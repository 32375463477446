import React from "react";
import { Helmet } from "react-helmet";

const ChiSiamo = () => {
  return (
    <div>
      <Helmet>
        <title>Chi Siamo - Nome Azienda</title>
        <meta
          name="description"
          content="Scopri di più su di noi e sulla nostra missione. Siamo un'azienda leader nel settore."
        />
      </Helmet>
      <section id="about" className="py-12 bg-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-gray-900">Chi Siamo</h2>
          <p className="mt-4 text-gray-700">
            Siamo un'azienda leader nel settore con anni di esperienza. La
            nostra missione è fornire prodotti e servizi di alta qualità ai
            nostri clienti.
          </p>
          <div className="mt-8 flex justify-center">
            <img
              src="https://via.placeholder.com/300"
              alt="Team"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChiSiamo;
