import React from "react";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Home - Nome Azienda</title>
        <meta
          name="description"
          content="Scopri la nostra azienda, leader nel settore con soluzioni innovative. Offriamo prodotti e servizi di alta qualità."
        />
        <meta
          name="keywords"
          content="azienda, prodotti, servizi, innovazione, qualità"
        />
      </Helmet>
      <div className="container mx-auto text-center mt-12">
        <h1 className="text-4xl font-bold">Benvenuto nella MIA Azienda</h1>
        <h2 className="text-2xl mt-4">
          Leader nel Settore con Soluzioni Innovative
        </h2>
        <p className="text-gray-700 mt-4">
          Offriamo prodotti e servizi di alta qualità, progettati per soddisfare
          le esigenze dei nostri clienti.
        </p>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-full mt-8 hover:bg-blue-700">
          Scopri di Più
        </button>
      </div>
      <section className="mt-12">
        <h2 className="text-3xl font-bold text-center">I Nostri Servizi</h2>
        <div className="flex justify-around mt-8">
          <div className="service-card p-4">
            <h3 className="text-xl font-semibold">Servizio 1</h3>
            <p>
              Descrizione breve del servizio 1 che evidenzia i suoi benefici.
            </p>
          </div>
          <div className="service-card p-4">
            <h3 className="text-xl font-semibold">Servizio 2</h3>
            <p>
              Descrizione breve del servizio 2 che evidenzia i suoi benefici.
            </p>
          </div>
          <div className="service-card p-4">
            <h3 className="text-xl font-semibold">Servizio 3</h3>
            <p>
              Descrizione breve del servizio 3 che evidenzia i suoi benefici.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-12">
        <h2 className="text-3xl font-bold text-center">Il Nostro Team</h2>
        <div className="mt-8 flex justify-center">
          <img
            src="https://via.placeholder.com/300"
            alt="Il nostro team di esperti"
            className="rounded-lg shadow-lg"
          />
        </div>
      </section>
    </div>
  );
};

export default Home;
