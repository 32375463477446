import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-800 text-white py-4 mt-auto">
      <div className="container mx-auto text-center">
        <p>
          &copy; {new Date().getFullYear()} Nome Azienda. Tutti i diritti
          riservati.
        </p>
        <div className="mt-2">
          <a href="#privacy" className="text-gray-400 hover:text-gray-200">
            Privacy
          </a>{" "}
          |
          <a href="#terms" className="text-gray-400 hover:text-gray-200 ml-2">
            Termini e Condizioni
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
